.App {
  text-align: center;
  margin: 0;
  padding-bottom: 24px;
  padding-top: 24px;
  width: auto;
  height: 100vh;

  background-image: url("image/page-bg.jpg");
  background-repeat: repeat-y;
  background-size: cover;
}
